import { useAuthContext } from "../Providers/WithAuth";
import Account from "./Account";
import InformationTab from "./Tabs/InformationTab";
import OrganizationsTab from "./Tabs/OrganizationsTab";
import ApplicationsTab from "./Tabs/ApplicationsTab";
import {
  ApartmentOutlined,
  ApiOutlined,
  BankOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

const AdministrationAccount: React.FC = () => {
  const { user } = useAuthContext();

  const items = [
    {
      key: "information",
      label: (
        <>
          <InfoCircleOutlined /> Information
        </>
      ),
      children: <InformationTab user={user} />,
    },
    {
      key: "organizations",
      label: (
        <>
          <BankOutlined />
          Organizations
        </>
      ),
      children: <OrganizationsTab />,
    },
    {
      key: "applications",
      label: (
        <>
          <ApartmentOutlined />
          Applications
        </>
      ),
      children: <ApplicationsTab />,
    }
  ];

  return <Account tabs={items} />;
};

export default AdministrationAccount;
