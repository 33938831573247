import Cookies from "universal-cookie";
import { useMutation } from "@apollo/client";
import Api from "api";
import useLocalStorage from "hooks/useLocalStorage";
import { message } from "antd";
import { LoginMutation, LoginMutationVariables } from "types";

const useLogin = (setPageWait: any) => {
  const [auth, setAuth] = useLocalStorage("authorization", { authorized: false });

  const [csarLoginMutation, { loading: csarLoginLoading }] = useMutation<
    LoginMutation,
    LoginMutationVariables
  >(Api.User.Login(), {
    onCompleted: (res) => {
      console.log(
        "%cLogin: %cSetting Authorization, redirecting to Home",
        "color:steelblue",
        "color:green",
      );
      setPageWait(false);
      setAuth({ authorized: true });
      setTimeout(() => {
        window.location.href = "/home";
      }, 250);
    },
    onError: (error) => {
      console.log(error.message);
      message.error(error.message);
    },
  });

  const loginWithCsar = (email: string, password: string) => {
    csarLoginMutation({
      variables: {
        email,
        password,
      },
    });
  };

  return { loginWithCsar, csarLoginLoading };
};

export default useLogin;
